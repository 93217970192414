//
// accordions.scss
//

.accordion,
.accordion-custom {
  h4,
  .h4 {
    margin-bottom: 0;
  }
  .card,
  .accordion-item {
    border-radius: $border-radius;
    margin: 3px;
    + .card {
      margin-top: 0.5rem !important;
    }

    .dropzone-entered {
      outline: 4px solid $designage-primary;
      border-radius: $border-radius;
    }

    .card-header {
      // background-color: #3c4853;
      background-color: $bg-playlist-sequence-header;
      padding: 4px;
    }

    .card-body {
      background-color: $bg-playlist-card;
    }

    &:hover {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
    }
    .accordion-arrow {
      font-size: 1.2rem;
    }
    a {
      &.collapsed {
        i.accordion-arrow {
          &:before {
            content: '\F142';
          }
        }
      }
    }

    #accordion-asset-row {
      .card-body {
        padding-top: 4px;
      }
    }

    #accordion-playlist-row {
      .card-body {
        padding: 3px;

        .card {
          border: 0;
        }
      }

      #accordion-asset-row {
        .card-body {
          padding: 4px 20px;
        }
      }
    }
  }
}
