//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
  }
}

.page-item {
  & :first-child {
    .page-link {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }
  & :last-child {
    .page-link {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}
