//
// topbar.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  span.logo-lg {
    display: block;
  }
  span.logo-sm {
    display: none;
  }
  .logo-lg-text-dark {
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
  .logo-lg-text-light {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
  background-color: $logo-box-background;
  transition: all 0.2s ease-out;
}

/* Notification */
.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: $bg-topbar-dark;
  }

  .noti-icon {
    font-size: 21px;
    vertical-align: middle;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 10px;
  }

  .notify-item {
    padding: 12px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 5px;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-800;
      font-family: $font-family-secondary;

      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }
  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;

      .badge {
        margin-top: 4px;
      }
    }
  }
}
.profile-name-list {
  overflow: auto;
  max-height: 75vh;
  .profile-name:hover {
    background-color: $bg-playlist-card;
  }
}
.profile-dropdown {
  min-width: 17rem;
  max-width: 20rem;
  color: $text-standard;

  i {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 16px;
  }
}

.nav-user {
  padding: 0 12px !important;
  .profile-picture-container {
    width: 1.75rem;
    height: 1.75rem;
    display: inline-block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
