@import './forms';
.dp-input-container > .dp-picker-input {
  @extend .form-control-dark;
  width: 100% !important;
  height: calc(1em + 0.9rem + 2px) !important;
  padding: 0.15rem 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  border: 1px solid $border-form-dark;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-radius: 0.2rem !important;
}

.dp-time-select-controls {
  background: $bg-select-dark !important;
  border-radius: 5%;
}

.dp-time-select-display-hours,
.dp-time-select-display-minutes,
.dp-time-select-display-seconds,
.dp-time-select-control-up,
.dp-time-select-control-down {
  color: $text-dark !important;
}

.dp-time-select-controls > .dp-time-select-control-up:hover {
  background: $bg-content-container-dark !important;
}

.dp-time-select-controls > .dp-time-select-control-down:hover {
  background: $bg-content-container-dark !important;
}

.dp-day-calendar-container {
  background: $bg-form-dark !important;
}
.dp-nav-header-btn {
  background: $bg-form-dark !important;
  color: white;
}

.dp-calendar-nav-left {
  background: none !important;
}
.dp-calendar-nav-right {
  background: none !important;
}

.dp-calendar-weekday {
  color: white !important;
}

.dp-calendar-day {
  border-radius: 0 !important;
}

.dp-start-time .dp-input-container > .dp-picker-input {
  width: 60px !important;
  height: fit-content !important;
  padding: 12px !important;
}

.content-row-date .dp-input-container > .dp-picker-input {
  font-size: 12px;
  width: 106px !important;
  text-align: center;
  background-color: #323b44 !important;
}

.dp-playlist-date .dp-input-container > input:disabled::placeholder {
  color: gray !important;
}
