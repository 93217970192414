//
// borders.scss
//

.border {
  border: 1px solid $border-light;
}
.border-bottom {
  border-bottom: 1px solid $border-light !important;
}
.border-top {
  border-top: 1px solid $border-light !important;
}
.border-right {
  border-right: 1px solid $border-light !important;
}
.border-left {
  border-left: 1px solid $border-light !important;
}

.rounded {
  border-radius: $border-radius;
}
