.channel-selector {
  .ng-select-container {
    background-color: transparent !important;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
    -moz-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
    box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  }

  ng-dropdown-panel {
    .ng-dropdown-panel-items {
      div {
        .ng-option {
          background: #36404a;
        }
      }
    }
  }
}
