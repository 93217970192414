//
// badge.scss
//

.badge {
  color: $white;
  font-size: 12px;
  border-radius: $border-radius;
}

.bg-light {
  color: $gray-800;
  font-size: 12px;
  border-radius: $border-radius;
}
.bg-dark {
  border-radius: $border-radius;
}

// badge lighten

@mixin bg-light-variant($bg) {
  background-color: rgba($bg, 0.2);
  color: $bg !important;
}

@each $color, $value in $theme-colors {
  .bg-light-#{$color} {
    @include bg-light-variant($value);
  }
}

.bg-online {
  color: #fff;
  background-color: $statusOnline;
}
.bg-offline {
  color: #fff;
  background-color: $statusOffline;
}
.bg-ctrlOffline {
  color: #fff;
  background-color: $statusCtrloffline;
}
