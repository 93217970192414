/* The switch - the box around the slider */
.switch-button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  border-style: hidden;
  border-radius: $btn-border-radius;
  padding: 5px 2px;
}

.switch {
  position: relative;
  /* display: inline-block; */
  width: 30px;
  float: left;
  margin: auto 0.15em;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The Slider */
.slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  left: 2px;
  top: -7px;
  width: 30px;
  height: 15px;
  transition: 0.4s;
  -ms-transition: 0.4s;
  -webkit-transition: 0.4s;
}

.slider:before {
  position: absolute;
  background-color: #fff;
  top: -2px;
  left: 0;
  width: 19px;
  height: 19px;
  content: '';
  transition: 0.4s;
  -ms-transition: 0.4s;
  -webkit-transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(11px);
  -ms-transform: translateX(11px);
  -webkit-transform: translateX(11px);
}

.slider.round {
  border-radius: 8px;
}

.slider.round:before {
  border-radius: 50%;
}
