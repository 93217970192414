//
// alert.scss
//

.alert {
  padding: 0.5rem;
}
.alert-danger {
  background-color: unset;
  color: #ff92a2;
  border: none;
}
