//
// tables.scss
//

.table {
  background-color: unset !important;
  font-family: unset !important;
  color: $text-dark;
  td {
    border-top: 1px solid $table-border-color;
  }
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
  th {
    font-weight: $font-weight-semibold;
    font-family: $font-family-secondary;
    background-color: unset !important;
  }
  td {
    background-color: unset !important;
  }
}
//Table centered (Custom)
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.action-icon {
  color: $gray-600;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;

  &:hover {
    color: $gray-700;
  }
}
