//
// general.scss
//

html {
  position: relative;
  min-height: 100vh;
}

body {
  overflow: hidden;
}
