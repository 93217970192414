// Variables

$enable-cssgrid: true;

//
// custom-variables
//

$bg-color-light: #495561;
$border-light: #495561;
$border-color-accordion-tables: #1a1b1c;

//Background content
$bg-content-dark: #323b44;
$bg-content: $bg-content-dark;

// left-sidebar
$bg-leftbar: #ffffff;
$bg-leftbar-dark: #38414a;
$border-leftbar-dark: #3c4650;

// Leftbar width
$leftbar-width: 14rem;
$leftbar-width-collapsed: 3.8rem;

// Boxed layout width
$boxed-layout-width: 1300px;

// Logo box
$logo-box-background: #38414a;

// Dark leftbar menu color
$menu-item-color-dark: #38414a;
$menu-item-hover-color-dark: #38414a;
$menu-item-active-color-dark: #38414a;

//Menu item colors
$menu-item: #fff;
$menu-item-hover: #3bafda;
$menu-item-active: #3bafda;
$menu-sub-item-active: #3bafda;
$breadcrumb-item: #3bafda;
$breadcrumb-item-hover: #0382f1;
$breadcrumb-item-active: #fff;
$breadcrumb-sub-item-active: #3bafda;

//Topbar Background
$bg-topbar: $bg-content-dark;
$bg-topbar-light: #ffffff;
$bg-topbar-dark: $bg-content-dark;

// Content, Topbar and footer Height
$topbar-height: 50px;
$topbar-height-container: calc($topbar-height + 10px);
$footer-height: 40px;
$content-page-height: calc(100vh - #{$topbar-height + $footer-height});

// Secondary font
$font-family-secondary: 'Nunito', sans-serif;

// Font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: $font-weight-normal;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

//Content Containers
$bg-content-container-light: #ffffff;
$bg-content-container-dark: #36404a;
$border-content-container-dark: #36404a;
$bg-playlist-preview-container-dark: #272e35;
$bg-playlist-sequence-header: #3c4853;
$bg-playlist-sequence-header-disabled: #5d5d5d;
$bg-playlist-sequence-header-inactive: #6c525e;
$bg-playlist-sequence-header-active: #6c525e;
$bg-playlist-card: #414d59;

//Modal
$bg-modal-dark: #36404a;
$border-content-dark: $border-light;
$border-color-form-control-dark-disabled: #42505d;
$modal-xl: 80vw;

//Text
$text-dark: #fff;
$text-header: #fff;
$text-light: #000000;
$text-danger: #ff5656;
$text-standard: #fff;

//Form
$bg-form-dark: $bg-playlist-card;
$border-form-dark: $border-color-accordion-tables;
$color-form-dark: #a8b5c3;

$bg-select-dark: $bg-playlist-card;
$border-select-dark: $border-color-accordion-tables;

$bg-form-focused-dark: #495561;
$border-color-form-focused-dark: $border-color-accordion-tables;
$color-form-focused-dark: #fff;
$color-form-span: #8c8f92;

$background-color-form-control-dark-disabled: $bg-content-dark;
$border-color-form-control-dark-disabled: #363636c4;

//Thumbnail
$overlay-color: rgba(0, 0, 0, 0.55);
$thumbnail-btn-color: #f1a4a4;
$thumbnail-btn-hover-color: #f96161;

//
// Color system
//

// stylelint-disable
$gray-100: #f1f5f7;
$gray-200: #f7f7f7;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #98a6ad;
$gray-700: #6c757d;
$gray-800: #343a40;
$gray-900: rgb(50, 58, 70);

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$black: #000000;
$white: #ffffff;
$designage-primary: #4175b6;
$designage-secondary: #dc6b1b;
$blue: #3bafda;
$indigo: #675aa9;
$purple: #6559cc;
$pink: #f672a7;
$red: #f1556c;
$orange: #fd7e14;
$yellow: #bf9a10;
$green: #178863;
$teal: #02a8b5;
$cyan: #37cde6;
$statusOnline: #407241;
$statusCtrloffline: #414141;
$statusPaneloff: #17360e;
$statusOffline: #f1556c;
$statusInfo: #5367d0;
$statusWarning: #c38822;
$statusCard-name-overlay: rgba(0, 0, 0, 0.54);
$playbackStatusActive: #608a5c;
$playbackStatusInactive: #f1556c;
$playbackStatusWaiting: #caa522;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #e8eef6,
  100: #c6d6e9,
  200: #a0badb,
  300: #7a9ecc,
  400: #5e8ac1,
  500: #4175b6,
  600: #3b6daf,
  700: #3262a6,
  800: #2a589e,
  900: #1c458e,
  A100: #c8daff,
  A200: #95b8ff,
  A400: #6295ff,
  A700: #4884ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warn: (
  50: #fdebed,
  100: #fbccd3,
  200: #f8aab6,
  300: #f58898,
  400: #f36f82,
  500: #f1556c,
  600: #ef4e64,
  700: #ed4459,
  800: #eb3b4f,
  900: #e72a3d,
  A100: #ffffff,
  A200: #fff2f3,
  A400: #ffbfc5,
  A700: #ffa6ad,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #fbede4,
  100: #f5d3bb,
  200: #eeb58d,
  300: #e7975f,
  400: #e1813d,
  500: #dc6b1b,
  600: #d86318,
  700: #d35814,
  800: #ce4e10,
  900: #c53c08,
  A100: #fff2ef,
  A200: #ffcbbc,
  A400: #ffa389,
  A700: #ff906f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary: $designage-primary;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$blue: $blue;
$light: $gray-100;
$dark: $gray-900;
$pop-tracked: $purple;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'pink': $pink,
    'purple': $purple,
    'pop-tracked': $pop-tracked,
  ),
  $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark: $gray-800;
$color-contrast-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    15: 15%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 960px,
  xl: 1280px,
  xxl: 1920px,
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #323b44;
$body-color: $gray-700;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Shadow

$box-shadow-sm: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
$box-shadow: 0 0 8px 0 rgba(154, 161, 171, 0.3);
$box-shadow-lg: 0 0 45px 0 rgba($black, 0.12);

// Components
//
$component-active-color: $white;
$component-active-bg: $primary;

$caret-width: 0.25em;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 24px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// stylelint-enable value-keyword-case
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.9);

$line-height-base: 1.25;
$line-height-lg: ($line-height-base * 1.25);
$line-height-sm: ($line-height-base * 0.9);

$headings-margin-bottom: $spacer;
$headings-font-weight: 700;
$headings-line-height: 1.1;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;

$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);
$small-font-size: 0.75rem;

$text-muted: var(--text-muted, #6c757d);

$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $gray-200;

$list-inline-padding: 6px;

$border-width: 2px;
// $border-radius: 0.25rem;
$border-radius: calc(0.5rem - 1px);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.85rem;
$table-cell-padding-sm: 0.5rem;

$table-color: $text-standard;
$table-hover-color: $table-color;

$table-border-color: rgba(112, 119, 128, 0.25);

$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $gray-600;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 0.9rem;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-size: $input-btn-font-size;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-font-weight: $font-weight-normal;
$btn-focus-width: 0.15rem;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-link-disabled-color: $gray-500;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: 1rem;
$btn-border-radius-lg: 1.5rem;
$btn-border-radius-sm: 0.5rem;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: $input-btn-font-size;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-disabled-color: $gray-600;

$input-color: #212529;
$input-border-width: 1px;
$input-border-color: $gray-400;
$input-border-radius: 0.25rem;

$input-bg: $white;
$input-group-addon-bg: $dark;

$input-focus-box-shadow: none;
$input-focus-border-color: darken($input-border-color, 10%);

$input-placeholder-color: $gray-600;

$input-height-border: $input-border-width;
$input-height: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
);
$input-height-inner: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2}
);

$input-group-addon-border-color: $input-border-color;

$form-select-padding-y: 0.15rem;
$form-select-padding-x: 0.9rem;
$form-select-color: $input-color;
$form-hover-color: #30373e;
$form-select-font-size: $input-font-size;
$form-select-height: $input-height;
$form-select-indicator-color: white;

$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: $primary;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;
$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow:
  0 0 0 1px $body-bg,
  $input-btn-focus-box-shadow;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$form-range-track-width: 100%;
$form-range-track-height: 0.5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: $gray-300;
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow:
  0 0 0 1px $body-bg,
  $input-btn-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$form-range-thumb-active-bg: lighten($component-active-bg, 35%);

$form-file-height: $input-height;
$form-file-height-inner: $input-height-inner;
$form-file-focus-border-color: $input-focus-border-color;
$form-file-focus-box-shadow: none;
$form-file-height-inner: $input-height-inner;

$form-file-padding-y: $input-btn-padding-y;
$form-file-padding-x: $input-btn-padding-x;
$form-file-line-height: $input-btn-line-height;
$form-file-color: $input-color;
$form-file-bg: $input-bg;
$form-file-border-width: $input-border-width;
$form-file-border-color: $input-border-color;
$form-file-border-radius: $input-border-radius;
$form-file-box-shadow: none;
$form-file-button-color: $form-file-color;
$form-file-button-bg: $input-group-addon-bg;
$form-file-text: (
  en: 'Browse',
) !default;

// Form validation

$form-feedback-margin-top: $spacer;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  '#',
  '%23'
);

$form-validation-states: ();
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid,
    ),
  ),
  $form-validation-states
);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y: 0.25rem;
$dropdown-border-color: darken($light, 3%);
$dropdown-color: $white;
$dropdown-font-size: $font-size-base;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $gray-100;
$dropdown-link-hover-bg: #414d59;
$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: $gray-200;
$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: 1.2rem;
$dropdown-header-color: inherit;
$dropdown-min-width: 13rem;
// Dropdown Large
$dropdown-lg-width: 320px;

// Navs
$navtab-bg: $bg-topbar-dark;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;
$nav-tabs-link-active-color: $component-active-color;
$nav-tabs-link-active-bg: $component-active-bg;

// Pagination

$pagination-color: $dark;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $dark;
$pagination-active-bg: #545454;
$pagination-active-border-color: transparent;
// $pagination-active-bg: $component-active-bg;
// $pagination-active-border-color: $pagination-active-bg;

// Images

$bg-image-thumbnail: $bg-playlist-card;
$border-image-thumbnail: $border-light;

// Cards

$card-bg: $bg-content; /*#38414a;*/
$card-color: $white;
$card-cap-color: $white;
$card-spacer-x: $spacer;
$card-spacer-y: calc($spacer * 2 / 3);
$card-border-width: 0.05rem;
$card-border-color: $border-color-accordion-tables;
$card-border-radius: $border-radius;
$card-cap-bg: $bg-playlist-sequence-header;
$card-columns-margin: $grid-gutter-width;
$card-columns-gap: $grid-gutter-width;

$card-group-margin: calc($grid-gutter-width / 2);
$card-deck-margin: $card-group-margin;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 5rem;
$tooltip-border-radius: 0.2rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;
$tooltip-font-size: $font-size-base;
$tooltip-opacity: 0.8;
$tooltip-color: white;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Badges

$bg-font-weight: $font-weight-bold;
$bg-font-size: 75%;

// Modals
$modal-content-border-width: 0;
$modal-content-border-color: transparent;
$modal-content-border-radius: 10px;
$modal-backdrop-bg: $gray-900;
$modal-width-xl: 99vw;
$modal-height-xl: 99vh;
$modal-playlist-width: 90vw;
$modal-playlist-height: 90vh;
$modal-header-padding-x: 2.5rem;
$modal-header-padding-y: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;

// Progress bars
$progress-height: 0.75rem;
$progress-bar-bg: $primary;

// List group

$list-group-active-bg: $component-active-bg;

// Breadcrumbs

$breadcrumb-padding-y: calc($spacer/1.5);
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider: quote('\F142');
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-500;

// Close
$close-font-size: 1.4rem;
$close-text-shadow: none;

// Code
$code-color: $pink;

// AS Split
$as-gutter-color: #83909e;

// CDK Drag
$playlist-row-drag: rgba(71, 108, 141, 0.8);
$drag-border-radius: 0.7rem;

// Accordion
$accordion-body-padding-y: 0.5rem;
$accordion-body-padding-x: 0.5rem;
$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 1rem;
$accordion-border-width: 0.05rem;
$accordion-border-color: $border-color-accordion-tables;
$accordion-bg: $bg-playlist-card;
$accordion-color: $text-standard;
$accordion-button-bg: $bg-playlist-sequence-header;
$accordion-button-active-bg: $bg-playlist-sequence-header;
$accordion-button-color: $text-standard;
$accordion-button-active-color: $text-standard;

$accordion-button-focus-border-color: $accordion-border-color;
$accordion-button-focus-box-shadow: $accordion-border-color;

$accordion-icon-color: $accordion-button-color; //Doesn't work. Added instead new variable in styles.scss
$accordion-icon-active-color: $accordion-button-active-color; //Doesn't work. Added instead new variable in styles.scss
$accordion-button-focus-border-color: $as-gutter-color;
$accordion-button-focus-box-shadow: $as-gutter-color;

// Popovers

$popover-bg: $bg-content-container-dark;
$popover-header-bg: $accordion-button-bg;
$popover-border-color: $accordion-border-color;
$popover-border-radius: $border-radius;
$popover-border-width: $accordion-border-width;
$popover-header-padding-y: $accordion-button-padding-y;
$popover-header-padding-x: $accordion-button-padding-x;
$popover-font-size: $font-size-base;

// Spinners
$spinner-vertical-align: 0;
// Folder Explorer

$folderexplorer-border-width: 0.075rem;
$folderexplorer-border-style: solid none solid solid;
$folderexplorer-border-color: $border-color-accordion-tables;
$folderexplorer-background-color: $accordion-button-bg;
$folderexplorer-width: 40%;
