.thumbnail-container {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  margin-left: 15px;
  height: 150px;
  width: 150px;

  video {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    background-color: black;
  }

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  .progress {
    position: absolute;
    width: 141px;
    bottom: 5px;
    left: 5px;
  }

  .btn {
    transition: color 0.3s ease-in-out;
    font-size: 26px;
    color: $thumbnail-btn-color;

    &:hover {
      color: $thumbnail-btn-hover-color;
    }
  }
}
