.content-container-card {
  margin-top: 0px;
  background-color: $bg-content-container-dark;
  border: 1px solid $border-content-container-dark;
  border-radius: 0.5rem;
  color: $text-standard;
  max-width: 100%;
  overflow: auto;
  height: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-header;
  }

  hr {
    border-top: 1px solid $border-content-dark;
  }

  .header-title {
    font-size: 1rem;
    color: $text-standard;
  }

  .sub-header {
    font-size: 0.875rem;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .custom-selector {
    width: max-content;
  }

  .page-size-selector {
    width: 4rem;
  }

  .bg-light {
    background-color: $bg-color-light !important;
  }

  .header {
    margin-bottom: 15px;
  }

  .status-filter {
    width: 120px;
  }

  .search-input {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-bottom: 0;
  }

  .sub-header {
    margin-bottom: 24px;
  }

  .status-filter-dropdown-btn {
    width: 100px;
  }

  table {
    margin-top: 10px;
  }

  ngb-pagination {
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    height: 3rem;
    margin: 0 0.5rem;
  }

  .unavailable-message {
    text-align: center;
  }

  // table tr {
  //   cursor: pointer;
  // }

  .searching-message {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.list-container {
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 0.25rem;
  }

  .status-filter {
    width: 6.75rem;
    color: #fff;
  }

  .search-input {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-bottom: 0;
  }

  .sub-header {
    margin-bottom: 24px;
  }

  .status-filter-dropdown-btn {
    width: 100px;
  }

  .sm-media-view {
    display: none;
  }

  @media only screen and (max-width: 425px) {
    .sm-media-view {
      display: block;
    }
    .lg-media-view {
      display: none;
    }
  }

  .sm-btn {
    float: right;
  }

  table {
    margin-top: 10px;
  }

  ngb-pagination {
    justify-content: center;
    display: flex;
  }

  .unavailable-message {
    text-align: center;
  }

  table tr {
    cursor: pointer;
  }

  .searching-message {
    margin-left: 5px;
    vertical-align: middle;
  }
}
