//
// page-title.scss
//

.page-title-box {
  background-color: $white;
  margin: 0 -27px 30px;
  padding: 0 27px;
  box-shadow: 0 1px 1px rgba(50, 58, 70, 0.1);

  .page-title {
    font-size: 1.1rem;
    margin: 0;
    line-height: 60px;
    color: $gray-900;
  }
  .page-title-right {
    float: right;
    margin-top: 15px;
  }

  .breadcrumb {
    padding-top: 5px;
  }
}

@include media-breakpoint-down(md) {
  .page-title-box {
    .page-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 70px;
    }
    .breadcrumb {
      display: none;
    }
  }
}

@media (max-width: 640px) {
  .page-title-box {
    .page-title-right {
      display: none;
    }
  }
}

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
