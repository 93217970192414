@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-ExtraBold.woff2') format('woff2'),
    url('../../../fonts/Nunito-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-ExtraBoldItalic.woff2') format('woff2'),
    url('../../../fonts/Nunito-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-Bold.woff2') format('woff2'),
    url('../../../fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-BoldItalic.woff2') format('woff2'),
    url('../../../fonts/Nunito-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-SemiBold.woff2') format('woff2'),
    url('../../../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-SemiBoldItalic.woff2') format('woff2'),
    url('../../../fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-Regular.woff2') format('woff2'),
    url('../../../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-Italic.woff2') format('woff2'),
    url('../../../fonts/Nunito-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-Light.woff2') format('woff2'),
    url('../../../fonts/Nunito-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-LightItalic.woff2') format('woff2'),
    url('../../../fonts/Nunito-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-ExtraLight.woff2') format('woff2'),
    url('../../../fonts/Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../../../fonts/Nunito-ExtraLightItalic.woff2') format('woff2'),
    url('../../../fonts/Nunito-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
