//
// popover.scss
//

.popover {
  max-width: 100% !important;
  &.debug-popover {
    max-width: fit-content !important;
  }
}

.bs-popover-right > .popover-arrow::after {
  border-right-color: $popover-border-color;
}
.bs-popover-top > .popover-arrow::after {
  border-top-color: $popover-border-color;
}
.bs-popover-left > .popover-arrow::after {
  border-left-color: $popover-border-color;
}
.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: $popover-border-color;
}

// .popover-header {
//   margin-top: 0;
// }

.popover-body {
  color: white;
}

.mat-mdc-menu-panel {
  padding: 0.5rem;
  background-color: $popover-bg !important;
  border-color: $popover-border-color !important;
  border-radius: $popover-border-radius !important;
  border-width: $popover-border-width !important;
  .mat-mdc-menu-item {
    color: white;
    &:not([disabled]):hover {
      background-color: #30373e !important;
    }
  }
}
