//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba(($value), 0.25) !important;
  }
}

.bg-online {
  background-color: $statusOnline;
}

.bg-not-installed {
  background-color: $statusCtrloffline;
}

.bg-offline-warning {
  background-color: $statusWarning;
}

.bg-offline {
  background-color: $statusOffline;
}

.status-ctrlOffline {
  background-color: $statusCtrloffline;
  border-color: $statusCtrloffline;
}

.status-offline {
  background-color: $statusOffline;
  border-color: $statusOffline;
}

.status-online {
  background-color: $statusOnline;
  border-color: $statusOnline;
}
