//
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-weight: $font-weight-bold;
  font-family: $font-family-secondary;
  color: $text-header;
}

// Font sizes (Custom)
.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

// Font Family
.font-family-secondary {
  font-family: $font-family-secondary;
}

// Font weight

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}
