.mapboxgl-popup-content {
  font-family: $font-family-base;
  padding: 0 !important;
  background-color: $bg-content;
  box-shadow: 2px 1px 5px 0 #000000;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: $bg-content;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: $bg-content;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: $bg-content;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $bg-content;
}
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: $bg-content;
}
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: $bg-content;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: $bg-content;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $bg-content;
}

.mapboxgl-popup-close-button {
  color: $text-header;
}
